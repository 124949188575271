@charset "UTF-8";
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Light.woff");
  font-weight: 300; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.woff");
  font-weight: 400; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.woff");
  font-weight: 500; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-SemiBold.woff");
  font-weight: 600; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Bold.woff");
  font-weight: 700; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraBold.woff");
  font-weight: 800; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Black.woff");
  font-weight: 900; }

html {
  font-size: 16px;
  height: 100%; }

body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

*,
*::before,
*::after {
  box-sizing: border-box; }

ul[class],
ol[class] {
  list-style: none;
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0; }

a:not([class]) {
  text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

input,
button,
textarea,
select {
  font: inherit; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

textarea {
  resize: none; }

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column; }

.content {
  flex: 1 0 auto; }

.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 15px; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

:root {
  /*--------Main--------*/
  --ui-main-color: #53C48A;
  --ui-button-bg: #53C48A;
  --ui-input-border-color: #b6b6b6;
  --ui-checkbox-border: #53C48A;
  --ui-checkbox-bg: #53C48A;
  --ui-swith-color: #E1E0E0;
  --ui-error-color: #EB5757;
  --ui-success-color: #27AE60;
  --ui-select-border: #b6b6b6;
  --ui-popup-bg: #fff;
  --font-color: #25262D;
  --font-color-secondary: #9EA2A9;
  --font-color-third: #808080;
  --grey: #9EA2A9;
  --main-color: #5BC044; }

* {
  box-sizing: border-box; }

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }

img {
  width: 100%; }

svg {
  width: 100%;
  height: auto; }

a {
  text-decoration: none; }

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

/*--------------Inputs---------------*/
.input {
  position: relative;
  width: 100%;
  margin-bottom: 16px; }
  .input--flex {
    flex: 1;
    margin-bottom: 0; }
  .input-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 100%;
    gap: 16px; }
    .input-row--start {
      align-items: flex-start; }
    .input-row--margin-none {
      margin-bottom: 0; }
  .input-title {
    display: block;
    font-size: 16px;
    line-height: 19px; }
  .input__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .input__switch {
    margin-bottom: 8px; }
  .input__name {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500; }
    .input__name--center {
      text-align: center; }
    .input__name--bold {
      font-weight: 700; }
  .input__notification {
    margin: 8px 0 0 0;
    font-size: 14px;
    color: var(--ui-error-color); }
    .input__notification--regular {
      color: #999999; }
  .input--error .input-item {
    border: 1px solid var(--ui-error-color); }
  .input--success .input-item {
    border: 1px solid var(--ui-success-color); }

.input-textarea {
  min-height: 100px; }

.input-notify {
  display: flex;
  align-items: flex-start;
  padding-top: 6px; }
  .input-notify--padding-none {
    padding-bottom: 0; }
  .input-notify__link {
    font-weight: 400;
    display: inline;
    font-size: 12px;
    line-height: 22px; }
  .input-notify__text {
    display: block;
    font-size: 12px;
    line-height: 22px;
    padding-left: 12px;
    color: #8f9bba; }
  .input-notify__char {
    display: block;
    font-size: 14px;
    line-height: 22px; }

.input-wrapper {
  position: relative; }
  .input-wrapper--flex {
    flex: 1;
    display: flex;
    align-items: center; }
  .input-wrapper--value::after {
    position: absolute;
    content: '%';
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: normal; }
  .input-wrapper--value .input-item {
    padding: 0 25px 0 10px; }

.input-item {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 15px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  line-height: normal;
  transition: all 0.15s ease; }
  .input-item.placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:-moz-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item::-moz-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:-ms-input-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item::-webkit-input-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:focus {
    box-shadow: 0 0 4px #53c48a; }
    .input-item:focus::placeholder {
      opacity: 0; }
  .input-item--textarea {
    padding: 15px 12px;
    height: 108px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
    .input-item--textarea::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px; }
  .input-item--full-height {
    height: 100%; }
  .input-item--padding {
    padding: 8px 52px 8px 16px; }
  .input-item--transparent {
    border: 0;
    background-color: transparent;
    padding: 0;
    flex: 1;
    min-width: 5px; }
    .input-item--transparent:focus {
      box-shadow: none; }
  .input-item--green {
    font-size: 14px;
    color: #53C48A; }
  .input-item--left-icon {
    padding-left: 52px; }
  .input-item--right-icon {
    padding-right: 52px; }
  .input-item--bigger {
    height: 52px;
    font-size: 16px; }
  .input-item--small {
    width: 102px;
    height: 48px; }

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  left: 15px;
  width: 24px; }
  .input-icon--small {
    width: 19px; }
  .input-icon svg {
    width: 100%;
    height: auto; }
  .input-icon .stroke path {
    stroke: #b6b6b6; }
  .input-icon--right {
    left: unset;
    right: 15px; }

.password-type {
  display: none; }

input[type='password'] ~ .show-pass .password-type {
  display: block; }

input[type='password'] ~ .show-pass .text-type {
  display: none; }

.show-pass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: flex;
  width: 16px; }
  .show-pass svg {
    width: 100%;
    height: auto; }
  .show-pass .fill path {
    fill: #b6b6b6; }
  .show-pass .stroke path {
    stroke: #b6b6b6; }

.select-block__name {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500; }

.select {
  position: relative;
  display: flex;
  align-content: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid var(--ui-input-border-color);
  background: #fff;
  z-index: 3; }
  .select__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .select__current-arrow {
    display: flex;
    flex: 0 0 8px;
    max-width: 8px;
    margin-left: 10px; }
    .select__current-arrow .stroke path {
      stroke: #424242; }
  .select__current-text {
    margin-right: auto; }
  .select__img {
    display: flex;
    width: 16px;
    align-items: center;
    margin-right: 12px; }
  .select__drop {
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    padding: 5px;
    background: #ffffff;
    border: 1px solid var(--ui-select-border);
    border-radius: 4px;
    z-index: 1; }
    .select__drop ul > li > a,
    .select__drop ul > li > button {
      display: block;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      color: #000;
      transition: all 0.15s ease;
      text-align: left; }
      .select__drop ul > li > a:hover,
      .select__drop ul > li > button:hover {
        background: #f4f4f4; }
  .select__drop-scroll {
    max-height: 180px;
    overflow: auto;
    scrollbar-color: #b6b6b6 transparent;
    scrollbar-width: thin; }
    .select__drop-scroll::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .select__drop-scroll::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #b6b6b6; }
  .select.active {
    z-index: 5; }
    .select.active .select__drop {
      display: block; }
  .select--small {
    padding: 0;
    border: 0;
    width: 62px;
    z-index: auto; }
    .select--small .select__drop {
      padding: 0;
      min-width: 100px; }
  .select--grey {
    background: #dadada;
    padding: 0 15px;
    width: 74px; }
  .select--light .select__current {
    color: #b6b6b6;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px; }
  .select--big {
    height: 52px;
    font-size: 16px; }

/*--------------End_Inputs-----------*/
/*--------------Buttons-------------*/
button {
  background: none;
  border: none;
  outline: none;
  padding: 0; }

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 110px;
  height: 48px;
  padding: 0 15px;
  background: var(--ui-button-bg);
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  transition: all 0.15s ease; }
  .button:hover {
    background: #3eb678; }
  .button--type2 {
    background: transparent;
    border: 1px solid var(--ui-button-bg);
    color: #000; }
    .button--type2:hover {
      background: var(--ui-button-bg);
      color: #fff; }
  .button--red {
    background-color: #eb5757; }
  .button--second-type {
    background: transparent;
    border: 1px solid var(--ui-button-bg);
    color: var(--ui-button-bg);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px; }
    .button--second-type .stroke path {
      stroke: var(--ui-button-bg); }
    .button--second-type:hover {
      background: var(--ui-button-bg);
      color: #fff; }
      .button--second-type:hover .stroke path {
        stroke: #fff; }
  .button--green {
    background-color: #5bc044; }
    .button--green:hover {
      background: #27ae60; }
  .button--purple {
    background-color: #7840b0; }
    .button--purple:hover {
      background-color: #5e2a92; }
  .button--grey {
    background-color: #c4c4c4; }
    .button--grey:hover {
      background: #a6a6a6; }
  .button--rounded {
    border-radius: 22px; }
  .button--less-height {
    height: 36px; }
  .button--bigger-height {
    height: 60px;
    font-size: 18px; }
  .button--full-width {
    width: 100%; }
  .button--smallest {
    width: 83px;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    min-width: 83px;
    padding: 0;
    border-radius: 8px; }
  .button--small {
    width: 120px; }
  .button--regular {
    width: 160px; }
  .button--wide {
    width: 180px; }
  .button--wider {
    width: 200px; }
  .button--big {
    width: 240px; }
  .button:disabled {
    opacity: 0.5;
    background: #b6b6b6;
    cursor: default; }
  .button__text {
    display: block;
    padding-left: 16px; }
    .button__text--padding-none {
      padding-right: 0;
      padding-left: 0; }
  .button__arrow {
    display: flex;
    align-items: center;
    margin: 0 9px;
    width: 6px; }
  .button__icon {
    display: flex;
    width: 12px;
    margin-left: 10px; }
    .button__icon .fill path {
      fill: #fff; }

/*--------------End_Buttons---------*/
/*-----Checkbox and Radiobutton-----*/
.checkbox {
  margin: 0 0 10px 0; }
  .checkbox__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 16px;
    height: 16px;
    border: 1px solid var(--ui-checkbox-border);
    border-radius: 2px;
    cursor: pointer; }
  .checkbox__item-icon {
    display: flex;
    width: 10px;
    opacity: 0; }
    .checkbox__item-icon .fill path {
      fill: #fff; }
  .checkbox__label {
    display: flex;
    align-items: center; }
  .checkbox__text {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer; }
    .checkbox__text a {
      color: blue; }
  .checkbox input:checked ~ .checkbox__item {
    background: var(--ui-checkbox-bg);
    border-color: var(--ui-checkbox-bg); }
    .checkbox input:checked ~ .checkbox__item .checkbox__item-icon {
      opacity: 1; }
  .checkbox input:disabled ~ .checkbox__item {
    cursor: default;
    background: #b6b6b6; }
    .checkbox input:disabled ~ .checkbox__item + .checkbox__text {
      cursor: default; }

.radio {
  margin: 0 0 10px 0; }
  .radio__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--ui-checkbox-border);
    border-radius: 50%;
    cursor: pointer; }
    .radio__item:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      z-index: 2;
      opacity: 0; }
  .radio__label {
    display: flex;
    align-items: center; }
  .radio__text {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer; }
    .radio__text a {
      color: blue; }
  .radio input:checked ~ .radio__item {
    background: var(--ui-checkbox-bg);
    border-color: var(--ui-checkbox-bg); }
    .radio input:checked ~ .radio__item:after {
      opacity: 1; }
  .radio input:disabled ~ .radio__item {
    cursor: default;
    background: #b6b6b6; }
    .radio input:disabled ~ .radio__item + .radio__text {
      cursor: default; }

.switch {
  display: flex;
  align-items: center; }
  .switch__toggler {
    display: block;
    position: relative;
    width: 36px;
    height: 20px;
    border: 1px solid #53C48A;
    border-radius: 13px;
    cursor: pointer;
    background-color: #53C48A; }
    .switch__toggler:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 100%;
      transition: 0.3s; }
  .switch input:checked ~ .switch__toggler {
    background: #53C48A; }
    .switch input:checked ~ .switch__toggler:before {
      left: 17px;
      background: #fff; }
  .switch__label + .switch__text {
    margin-left: 10px; }
  .switch__text {
    font-size: 14px;
    font-weight: 500; }
    .switch__text + .switch__label {
      margin-left: 10px; }
  .switch--type2 .switch__toggler {
    background: var(--ui-swith-color); }
    .switch--type2 .switch__toggler:before {
      background: #fff; }
  .switch--type2 input:checked ~ .switch__toggler {
    background: var(--ui-main-color); }
    .switch--type2 input:checked ~ .switch__toggler:before {
      background: #fff; }

/*---End_Checkbox and Radiobutton---*/
/*-------------Popup----------------*/
.popup-open {
  position: relative;
  overflow: hidden; }

.popup-window {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 201;
  background: rgba(154, 159, 167, 0.8);
  backdrop-filter: blur(7px); }
  .popup-window__inside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 20px; }

.popup {
  position: relative;
  width: 400px;
  max-width: 100%;
  border-radius: 10px;
  background: var(--ui-popup-bg);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 30px; }
  .popup--big {
    width: 612px;
    padding: 40px; }
  .popup--second-type {
    width: 531px; }
    .popup--second-type .popup-header__title {
      font-size: 18px;
      line-height: 18px;
      font-weight: normal; }
    .popup--second-type .popup__close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eeeeee;
      padding: 8px; }
      .popup--second-type .popup__close path {
        fill: #999999; }
  .popup__img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eff4fb;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 16px;
    width: 65px;
    height: 65px; }
  .popup__close {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 20px;
    height: 20px; }
    .popup__close--absolute {
      position: absolute;
      right: 40px;
      top: 40px; }
      @media screen and (max-width: 575px) {
        .popup__close--absolute {
          top: 20px;
          right: 20px; } }
    .popup__close svg {
      width: 100%;
      height: auto; }
    .popup__close .fill path {
      fill: #000; }
  .popup__content--center {
    text-align: center; }
  .popup__content--padding {
    padding-top: 24px; }
  .popup__btn {
    margin-top: 24px;
    width: 212px;
    height: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px; }
    .popup__btn--second-type {
      width: 220px;
      background-color: #000000;
      margin-top: 14px; }
      .popup__btn--second-type:hover {
        background-color: #252525; }
  .popup__title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 10px; }
    @media screen and (max-width: 575px) {
      .popup__title {
        font-size: 14px;
        line-height: 24px; } }
  .popup__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 30px; }
    @media screen and (max-width: 575px) {
      .popup__text {
        font-size: 14px;
        line-height: 24px; } }
    .popup__text--second-type {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px; }
  .popup__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px; }
    @media screen and (max-width: 575px) {
      .popup__footer-btn {
        width: 100%; } }

.popup-header {
  display: flex;
  align-items: center; }
  .popup-header--center {
    justify-content: center; }
  .popup-header__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700; }
    .popup-header__title--center {
      display: block;
      width: 100%;
      margin-bottom: 15px; }

.popup-body {
  margin-top: 20px; }

.popup-text {
  margin: 0 0 15px 0;
  line-height: 2;
  font-size: 15px; }
  .popup-text--center {
    text-align: center; }

.popup-submit {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .popup-submit--less-mt {
    margin-top: 15px; }
  .popup-submit--more-mt {
    margin-top: 45px; }
  .popup-submit__col {
    width: calc(50% - 10px); }
  .popup-submit--sb {
    justify-content: space-between; }

@media screen and (max-width: 767px) {
  .popup {
    padding: 20px 15px 20px 15px; }
  .popup-header__title {
    font-size: 21px; } }

/*-------------End_Popup------------*/
.link--green {
  color: #53C48A;
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  margin-top: 10px; }

.link--margin {
  margin-top: 24px; }

.link--left {
  text-align: left;
  margin-top: 0; }

:root {
  /*--------Main--------*/
  --ui-main-color: #53C48A;
  --ui-button-bg: #53C48A;
  --ui-input-border-color: #b6b6b6;
  --ui-checkbox-border: #53C48A;
  --ui-checkbox-bg: #53C48A;
  --ui-swith-color: #E1E0E0;
  --ui-error-color: #EB5757;
  --ui-success-color: #27AE60;
  --ui-select-border: #b6b6b6;
  --ui-popup-bg: #fff;
  --font-color: #25262D;
  --font-color-secondary: #9EA2A9;
  --font-color-third: #808080;
  --grey: #9EA2A9;
  --main-color: #5BC044; }

* {
  box-sizing: border-box; }

button {
  padding: 0;
  border: none;
  background: none; }

img {
  width: 100%; }

svg {
  width: 100%;
  height: auto; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

html body {
  color: var(--font-color);
  font-family: 'Roboto', sans-serif;
  background: #f4f7fe;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400; }

.user-toggle {
  display: flex;
  align-items: center;
  gap: 18px; }
  .user-toggle__title {
    display: block;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px; }

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px; }
  .main-header .count-label {
    margin-top: 0; }

.header {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 8px 12px;
  background: #53C48A; }
  .header__logo {
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    background: #3b3d48;
    border-radius: 50%; }
    .header__logo img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%; }

.mob-nav-btn {
  display: none;
  margin-right: 15px;
  width: 16px; }

.authorization-section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 50px 0 30px 0; }

.authorization-form {
  width: 337px;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 8px; }
  .authorization-form__logo {
    margin: 0 auto 10px auto;
    width: 50px; }
  .authorization-form__body {
    margin-top: 30px; }

.form-title {
  font-weight: 500;
  font-size: 24px;
  text-align: center; }

.form-submit {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 24px; }
  .form-submit--start {
    justify-content: flex-start; }
  .form-submit--margin-none {
    margin-top: 0; }

.sidebar {
  width: 250px;
  padding: 12px;
  background: #fff;
  transition: all 0.3s ease; }
  .sidebar__action {
    position: relative;
    display: flex;
    flex: 0 0 24px;
    max-width: 24px;
    margin: 0 0 0 auto;
    z-index: 2; }
    .sidebar__action .stroke path {
      stroke: #3b3d48; }

.main-section {
  display: flex;
  min-height: calc(100vh - 64px);
  transition: all 0.3s ease; }
  .main-section.minimized .sidebar {
    width: 50px;
    padding: 12px 0;
    transition: width 0.3s ease; }
    .main-section.minimized .sidebar__action {
      margin: 0 auto;
      transform: rotate(180deg); }
  .main-section.minimized .sidebar-nav__subitem {
    display: none; }
  .main-section.minimized .sidebar-nav__list > li > a,
  .main-section.minimized .sidebar-nav__list > li > button {
    justify-content: center;
    padding: 12px 0;
    font-size: 0; }
  .main-section.minimized .sidebar-nav__icon {
    margin: 0; }
  .main-section.minimized .main-content {
    width: calc(100% - 50px); }

.main-content {
  width: calc(100% - 250px);
  padding: 30px 16px;
  transition: width 0.3s ease; }
  .main-content--flex {
    display: flex;
    flex-direction: column; }

.sidebar-nav {
  margin-top: 15px; }
  .sidebar-nav__icon {
    display: flex;
    flex: 0 0 15px;
    max-width: 15px;
    margin: 0 15px 0 0; }
    .sidebar-nav__icon svg {
      max-height: 15px; }
    .sidebar-nav__icon .stroke path {
      stroke: var(--font-color-third);
      transition: all 0.3s ease; }
    .sidebar-nav__icon .fill path {
      fill: var(--font-color-third);
      transition: all 0.3s ease; }
  .sidebar-nav__subitem {
    padding: 13px 12px 13px 47px; }
    .sidebar-nav__subitem--active .sidebar-nav__sublink {
      font-weight: bold; }
  .sidebar-nav__sublink {
    color: #778192;
    font-size: 15px;
    line-height: 22px; }
    .sidebar-nav__sublink:hover {
      font-weight: bold; }
  .sidebar-nav__list > li > a,
  .sidebar-nav__list > li > button {
    display: flex;
    align-items: center;
    padding: 13px 16px;
    border-radius: 4px;
    font-size: 15px;
    color: var(--font-color-third);
    transition: all 0.3s ease; }
    .sidebar-nav__list > li > a.active, .sidebar-nav__list > li > a:hover,
    .sidebar-nav__list > li > button.active,
    .sidebar-nav__list > li > button:hover {
      background: #53C48A;
      color: #fff;
      font-weight: 500; }
      .sidebar-nav__list > li > a.active .sidebar-nav__icon .stroke path, .sidebar-nav__list > li > a:hover .sidebar-nav__icon .stroke path,
      .sidebar-nav__list > li > button.active .sidebar-nav__icon .stroke path,
      .sidebar-nav__list > li > button:hover .sidebar-nav__icon .stroke path {
        stroke: #fff; }
      .sidebar-nav__list > li > a.active .sidebar-nav__icon .fill path, .sidebar-nav__list > li > a:hover .sidebar-nav__icon .fill path,
      .sidebar-nav__list > li > button.active .sidebar-nav__icon .fill path,
      .sidebar-nav__list > li > button:hover .sidebar-nav__icon .fill path {
        fill: #fff; }

.title {
  font-size: 29px;
  line-height: 1.4; }
  .title--small {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px; }
  .title--padding-bottom {
    padding-bottom: 15px; }

.title-block {
  display: flex;
  align-items: center; }
  .title-block__icon {
    display: flex;
    align-items: center;
    width: 34px;
    margin-right: 12px; }
    .title-block__icon svg {
      width: 100%;
      height: auto; }

.count-label {
  margin: 30px 0 0 0;
  font-size: 14px; }
  .count-label--bold {
    font-weight: 500; }

.table-block {
  margin-top: 22px;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px; }

.table {
  font-size: 14px; }
  .table--transaction {
    background: #f9f9f9; }
    .table--transaction .tr {
      grid-template-columns: 1.7fr 1fr 1fr; }
  .table--transaction-history .tr {
    grid-template-columns: 2.4fr 1.8fr 1fr 1fr 1fr; }
  .table--transactions .tr {
    grid-template-columns: 1fr 1fr 1fr 0.8fr 2.5fr 1fr 1fr 1fr 0.6fr; }
  .table--withdrawals .tr {
    grid-template-columns: 1fr 1fr 1fr 0.8fr 1.5fr 1.5fr 1fr 1fr 0.6fr; }
  .table--list-admin .tr {
    grid-template-columns: 1fr 1fr 1.2fr 2fr 1fr 1fr 0.7fr; }
  .table--medium .tr {
    grid-template-columns: 1fr 1fr 1fr; }
  .table--wallet .tr {
    grid-template-columns: 1fr 1fr 1fr 1.1fr 1.1fr 1.4fr 1.5fr; }
  .table--fee .tr {
    grid-template-columns: 1fr 1fr; }
  .table--six .tr {
    grid-template-columns: repeat(6, 1fr); }
  .table--five .tr {
    grid-template-columns: repeat(5, 1fr); }
  .table--history .tr {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr; }
  .table__block-title {
    display: block;
    padding: 18px 25px 10px 25px;
    font-size: 16px;
    line-height: 24px; }

.table-transaction {
  border: 1px solid #e3e7ee; }
  .table-transaction__name {
    display: flex;
    align-items: center;
    padding: 18px 16px; }
  .table-transaction__icon {
    display: flex;
    align-items: center;
    width: 15px;
    height: 15px; }
  .table-transaction__title {
    display: block;
    padding-left: 14px;
    color: #27ae60;
    font-size: 14px;
    line-height: 18px; }

.table-desc {
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #9ea2a9; }

.tr {
  display: grid;
  align-items: center; }

.td {
  padding: 12px 10px;
  word-break: break-word; }
  .td:first-child {
    padding-left: 25px; }
  .td:last-child {
    padding-right: 25px; }
  .td--flex {
    display: flex;
    gap: 10px; }
  .td--right {
    text-align: right; }
    .td--right-flex {
      display: flex;
      justify-content: flex-end; }
  .td--padding-left {
    padding-left: 25px; }

.table-header .tr {
  border-bottom: 1px solid #e8e8e8; }

.table-body .tr {
  border-bottom: 1px solid #e8e8e8; }
  .table-body .tr:last-child, .table-body .tr:only-child {
    border: none; }

.td-name {
  font-weight: 500;
  font-size: 12px; }

.table--user-management .tr {
  grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr 0.8fr 0.8fr 0.7fr; }

.table--user-management-waitlist .tr {
  grid-template-columns: 1fr 1fr 1fr 1.3fr 1fr 0.8fr 0.8fr 1.3fr; }

.table-buttons {
  display: flex; }
  .table-buttons button {
    display: flex;
    align-items: center;
    flex: 0 0 15px;
    max-width: 15px; }
    .table-buttons button .stroke path {
      stroke: var(--grey); }
    .table-buttons button.active .stroke path {
      stroke: var(--main-color); }
  .table-buttons button + button {
    margin-left: 15px; }
  .table-buttons--right {
    justify-content: flex-end; }

.td-hidden-name {
  display: none;
  font-weight: 500; }

.td-title {
  display: block;
  font-size: 12px;
  line-height: 18px;
  color: rgba(37, 38, 45, 0.5); }

.td-desc {
  font-size: 14px; }
  .td-desc--grey {
    color: rgba(37, 38, 45, 0.5); }

.link {
  color: #599dc4;
  text-decoration: none; }
  .link--margin-none {
    margin: 0; }

.admin {
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  color: #fff;
  font-size: 15px;
  font-weight: 500; }
  .admin__logout {
    display: flex;
    flex: 0 0 15px;
    max-width: 15px;
    margin-left: 14px; }
    .admin__logout .stroke path {
      stroke: #fff; }

.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 11px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500; }
  .status--verified {
    background: #dcecc8;
    color: #69a042; }
  .status--not-verified {
    background: #facdd4;
    color: #e77373; }
  .status--pending {
    background: #fff1d2;
    color: #e8aa29; }

.pagination-block {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px; }

.pagination {
  display: flex;
  align-items: center; }
  .pagination > li > a,
  .pagination > li > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    font-size: 14px;
    color: var(--font-color);
    transition: all 0.15s ease; }
    .pagination > li > a svg,
    .pagination > li > button svg {
      width: 9px; }
    .pagination > li > a.arrow .stroke path,
    .pagination > li > button.arrow .stroke path {
      stroke: var(--main-color); }
    .pagination > li > a.disabled,
    .pagination > li > button.disabled {
      cursor: default; }
      .pagination > li > a.disabled .stroke path,
      .pagination > li > button.disabled .stroke path {
        stroke: #9ea2a9; }
    .pagination > li > a.active,
    .pagination > li > button.active {
      border-color: var(--main-color);
      color: var(--main-color); }
    .pagination > li > a:hover,
    .pagination > li > button:hover {
      color: var(--main-color); }
  .pagination > li + li {
    margin-left: 10px; }

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .breadcrumbs__item {
    margin-right: 15px;
    opacity: 0.5;
    color: #000;
    font-size: 12px;
    font-weight: 400; }
    .breadcrumbs__item:last-child {
      opacity: 1; }
  .breadcrumbs__icon {
    display: flex;
    width: 9px;
    margin-right: 15px;
    opacity: 0.5; }
    .breadcrumbs__icon .stroke path {
      stroke: #000; }

.panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px; }
  .panel--margin-none {
    margin-top: 0; }

.user-block {
  display: flex;
  align-items: center; }
  .user-block__name {
    margin-right: 12px;
    font-size: 14px; }

.back-btn {
  display: flex;
  flex: 0 0 22px;
  width: 22px;
  max-width: 22px;
  margin-right: 12px; }
  .back-btn .fill path {
    fill: #000; }
  .back-btn--small {
    width: 10px;
    max-width: 10px;
    flex: 0 0 10px; }

.filters {
  display: flex;
  margin-left: auto; }

.filter {
  width: 130px;
  margin-right: 16px; }
  .filter:last-child {
    margin: 0; }

.content-block {
  margin-top: 22px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 4px; }
  .content-block--margin-none {
    margin-top: 0; }
  .content-block__inside {
    padding: 30px; }
    .content-block__inside--bt {
      border-top: 1px solid #e3e7ee; }

.content-row {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  flex-wrap: wrap; }
  .content-row--small {
    max-width: 694px; }

.forms-block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px; }

.block-title {
  font-size: 16px;
  font-weight: 500; }

.user-transaction__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.user-info {
  display: flex;
  flex-wrap: wrap; }
  .user-info__block:not(:last-child) {
    margin-right: 70px; }

.personal-info__name {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px; }

.personal-info__desc {
  display: flex;
  align-items: flex-end; }
  .personal-info__desc:hover .personal-info__text {
    text-decoration: underline; }

.personal-info__text {
  display: block;
  font-size: 16px;
  line-height: 18px;
  padding-right: 8px; }

.personal-info__edit {
  width: 30px;
  height: 30px;
  background: #27ae60;
  border-radius: 3px;
  padding: 0;
  min-width: 30px; }
  .personal-info__edit:hover {
    background: #1b9850; }

.operation {
  display: flex;
  align-items: center; }
  .operation__status {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #27ae60;
    width: 30px;
    height: 30px; }
  .operation__status-icon {
    display: flex;
    align-items: center; }
  .operation__info {
    padding-left: 8px;
    flex: 1; }
  .operation__title {
    display: block;
    font-size: 14px;
    line-height: 18px; }
  .operation__desc {
    display: block;
    padding-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #9ea2a9; }

.level {
  display: flex;
  align-items: center;
  margin-left: auto; }
  .level__title {
    display: block;
    padding-right: 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%; }
  .level .select--small .select__drop {
    min-width: 100%; }

.stats {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .stats__header {
    display: flex;
    align-items: baseline; }
  .stats__title {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 16px;
    padding-right: 16px; }
  .stats__desc {
    display: block;
    font-size: 14px;
    line-height: 16px; }
  .stats__info {
    max-width: 515px;
    margin-right: 30px; }
    .stats__info--full {
      max-width: 100%; }
  .stats__list {
    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 16px; }

.stats-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  width: 48%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px; }
  .stats-item--small {
    flex-direction: column;
    width: 120px;
    align-items: flex-start; }
    .stats-item--small .stats-item__desc {
      padding-top: 5px; }
  .stats-item--active {
    background-color: var(--main-color);
    color: #fff; }
  .stats-item__name {
    display: flex;
    align-items: center; }
  .stats-item__img {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #c4c4c4;
    margin-right: 6px; }
  .stats-item__title {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px; }
  .stats-item__desc {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px; }

.chart {
  margin-right: 70px; }

.forms-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; }

.forms-block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px; }
  .forms-block--big {
    max-width: 674px;
    flex: 1; }
    .forms-block--big .form {
      width: 100%;
      padding: 10px 57px 30px 30px; }
    .forms-block--big .input__name {
      font-size: 20px; }

.trade-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  padding: 15px;
  margin: 0 auto; }

.form {
  margin-top: 22px;
  width: 355px;
  max-width: 100%;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 30px; }
  .form--regular {
    border: none;
    padding: 0;
    max-width: 100%;
    width: 100%; }
  .form--settings {
    width: 352px; }
  .form--second-type {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    border: none;
    margin-top: 11px; }
    .form--second-type .form__content {
      flex: 1;
      padding-right: 34px;
      margin-top: 0; }
    .form--second-type .input {
      margin-bottom: 0; }
      .form--second-type .input:not(:last-child) {
        margin-bottom: 16px; }
  .form__content {
    margin-top: 20px; }
  .form__footer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 10px; }

.radio-row {
  display: flex;
  gap: 20px; }

.table-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px; }

.section-block {
  padding-top: 30px; }

.info-dropdown {
  position: relative;
  display: flex;
  align-items: center; }
  .info-dropdown:hover .info-dropdown__title {
    color: var(--purple); }
  .info-dropdown:hover .info-dropdown__menu {
    display: block; }
  .info-dropdown__menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 83%;
    padding-top: 6px;
    z-index: 1; }
  .info-dropdown__list {
    background: #f6f6f6;
    border: 1px solid #e3e7ee;
    padding: 4px 0;
    border-radius: 4px; }
  .info-dropdown__item {
    display: block;
    padding: 6px 11px;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap; }
    .info-dropdown__item:not(:last-child) {
      box-shadow: inset 0px -1px 0px #e8e8e8; }

.info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-left: 6px; }

.reports {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 30px; }
  .reports__item:not(:last-child) {
    margin-right: 16px; }

.report-item {
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 28px 32px;
  width: 352px; }
  .report-item__status {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding: 4px 10px;
    background: #ffd8d8;
    border-radius: 11px;
    color: #e46d6d; }
  .report-item__img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    overflow: hidden; }
  .report-item__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px; }
  .report-item__title {
    display: flex;
    align-items: center; }
  .report-item__icon {
    display: flex;
    align-items: center;
    width: 18px;
    height: 15px; }
  .report-item__text {
    display: block;
    padding-left: 12px;
    font-size: 18px;
    line-height: 18px; }
  .report-item__info {
    padding-right: 20px; }
  .report-item__price {
    display: block;
    padding-top: 28px;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px; }
  .report-item__footer {
    padding-top: 28px; }

.connect-data {
  max-width: 720px;
  background: #ffffff;
  border: 1px solid #e3e7ee;
  border-radius: 4px;
  padding: 22px 32px 36px 32px; }
  .connect-data--margin-top {
    margin-top: 16px; }
  .connect-data__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .connect-data__title {
    display: block;
    font-size: 18px;
    line-height: 18px;
    color: #5bc044; }
  .connect-data__send {
    height: 48px; }

.close-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e7ee;
  border-radius: 50%; }
  .close-btn__icon {
    display: flex;
    width: 10px; }
  .close-btn:hover {
    background-color: #e3e7ee; }

@media screen and (max-width: 1199px) {
  .td-hidden-name {
    display: block; }
  .table-header {
    display: none; }
  .table-buttons--right {
    justify-content: start; }
  .tr {
    display: flex;
    flex-wrap: wrap; }
  .td {
    width: 50%;
    padding: 5px 10px;
    text-align: left; }
    .td:first-child {
      padding-left: 10px;
      padding-top: 10px; }
    .td:last-child {
      padding-right: 10px;
      padding-bottom: 10px; }
  .stats {
    flex-direction: column;
    align-items: flex-start; }
  .user-info {
    width: 100%; }
    .user-info__block {
      margin-right: 30px; }
    .user-info + .button {
      margin-top: 20px; }
  .chart {
    margin-top: 30px; } }

@media screen and (max-width: 991px) {
  .stats__info {
    margin-right: 0; }
  .content-block {
    width: calc(100% + 30px);
    margin-left: -15px; }
    .content-block__inside {
      padding: 15px; }
  .table-transaction {
    margin-top: 0; }
  .mob-nav-btn {
    display: flex; }
  .sidebar {
    display: none;
    width: 100% !important;
    padding: 12px; }
    .sidebar__action {
      display: none; }
  .main-content {
    width: 100% !important; }
  .main-section {
    flex-wrap: wrap; }
    .main-section.minimized .sidebar {
      display: block;
      padding: 12px; }
    .main-section.minimized .sidebar-nav__list > li > a, .main-section.minimized .sidebar-nav__list > li > button {
      justify-content: flex-start;
      font-size: 16px;
      padding: 12px 15px; }
  .sidebar-nav__icon {
    margin: 0 10px 0 0 !important; }
  .sidebar-nav__list > li > a, .sidebar-nav__list > li > button {
    padding: 12px 15px; }
  .forms-block--big .form {
    padding: 20px 15px; }
  .report-item {
    width: calc(50% - 8px); }
  .connect-data {
    max-width: 100%; }
  .main-content--flex {
    display: block; } }

@media screen and (max-width: 767px) {
  .connect-data {
    padding: 20px 15px; }
  .form--second-type {
    flex-wrap: wrap;
    justify-content: flex-start; }
    .form--second-type .form__content {
      flex: 0 0 100%;
      padding: 0; }
  .connect-data__send {
    margin: 30px auto 0 auto; }
  .reports {
    padding-top: 15px; }
  .report-item {
    width: 100%;
    margin: 15px 0 0 0;
    padding: 20px 15px; }
  .level {
    margin: 15px 0 0 0; }
  .forms-row {
    gap: unset; }
  .forms-block {
    width: 100%;
    margin-top: 15px;
    padding: 0; }
    .forms-block .form {
      margin-top: 0; }
  .form {
    width: 100%;
    margin-top: 15px; }
  .stats__list .stats-item {
    width: 100%; }
    .stats__list .stats-item--small {
      width: calc(50% - 8px); }
  .panel {
    flex-direction: column;
    align-items: flex-start; }
  .filters {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 22px; }
  .filter {
    width: 100%;
    margin: 10px 0 0 0; }
    .filter:last-child {
      margin: 10px 0 0 0; } }

@media screen and (max-width: 400px) {
  .td {
    width: 100%; } }

@media screen and (max-width: 1199px) {
  .td-hidden-name {
    display: block; }
  .table-header {
    display: none; }
  .table-buttons--right {
    justify-content: start; }
  .tr {
    display: flex;
    flex-wrap: wrap; }
  .td {
    width: 50%;
    padding: 5px 10px;
    text-align: left; }
    .td:first-child {
      padding-left: 10px;
      padding-top: 10px; }
    .td:last-child {
      padding-right: 10px;
      padding-bottom: 10px; }
  .stats {
    flex-direction: column;
    align-items: flex-start; }
  .user-info {
    width: 100%; }
    .user-info__block {
      margin-right: 30px; }
    .user-info + .button {
      margin-top: 20px; }
  .chart {
    margin-top: 30px; } }

@media screen and (max-width: 991px) {
  .stats__info {
    margin-right: 0; }
  .content-block {
    width: calc(100% + 30px);
    margin-left: -15px; }
    .content-block__inside {
      padding: 15px; }
  .table-transaction {
    margin-top: 0; }
  .mob-nav-btn {
    display: flex; }
  .sidebar {
    display: none;
    width: 100% !important;
    padding: 12px; }
    .sidebar__action {
      display: none; }
  .main-content {
    width: 100% !important; }
  .main-section {
    flex-wrap: wrap; }
    .main-section.minimized .sidebar {
      display: block;
      padding: 12px; }
    .main-section.minimized .sidebar-nav__list > li > a, .main-section.minimized .sidebar-nav__list > li > button {
      justify-content: flex-start;
      font-size: 16px;
      padding: 12px 15px; }
  .sidebar-nav__icon {
    margin: 0 10px 0 0 !important; }
  .sidebar-nav__list > li > a, .sidebar-nav__list > li > button {
    padding: 12px 15px; }
  .forms-block--big .form {
    padding: 20px 15px; }
  .report-item {
    width: calc(50% - 8px); }
  .connect-data {
    max-width: 100%; }
  .main-content--flex {
    display: block; } }

@media screen and (max-width: 767px) {
  .connect-data {
    padding: 20px 15px; }
  .form--second-type {
    flex-wrap: wrap;
    justify-content: flex-start; }
    .form--second-type .form__content {
      flex: 0 0 100%;
      padding: 0; }
  .connect-data__send {
    margin: 30px auto 0 auto; }
  .reports {
    padding-top: 15px; }
  .report-item {
    width: 100%;
    margin: 15px 0 0 0;
    padding: 20px 15px; }
  .level {
    margin: 15px 0 0 0; }
  .forms-row {
    gap: unset; }
  .forms-block {
    width: 100%;
    margin-top: 15px;
    padding: 0; }
    .forms-block .form {
      margin-top: 0; }
  .form {
    width: 100%;
    margin-top: 15px; }
  .stats__list .stats-item {
    width: 100%; }
    .stats__list .stats-item--small {
      width: calc(50% - 8px); }
  .panel {
    flex-direction: column;
    align-items: flex-start; }
  .filters {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 22px; }
  .filter {
    width: 100%;
    margin: 10px 0 0 0; }
    .filter:last-child {
      margin: 10px 0 0 0; } }

@media screen and (max-width: 400px) {
  .td {
    width: 100%; } }

:root {
  /*--------Main--------*/
  --ui-main-color: #53C48A;
  --ui-button-bg: #53C48A;
  --ui-input-border-color: #b6b6b6;
  --ui-checkbox-border: #53C48A;
  --ui-checkbox-bg: #53C48A;
  --ui-swith-color: #E1E0E0;
  --ui-error-color: #EB5757;
  --ui-success-color: #27AE60;
  --ui-select-border: #b6b6b6;
  --ui-popup-bg: #fff;
  --font-color: #25262D;
  --font-color-secondary: #9EA2A9;
  --font-color-third: #808080;
  --grey: #9EA2A9;
  --main-color: #5BC044; }

* {
  box-sizing: border-box; }

button {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer; }

img {
  width: 100%; }

svg {
  width: 100%;
  height: auto; }

a {
  text-decoration: none; }

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

/*--------------Inputs---------------*/
.input {
  position: relative;
  width: 100%;
  margin-bottom: 16px; }
  .input--flex {
    flex: 1;
    margin-bottom: 0; }
  .input-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    width: 100%;
    gap: 16px; }
    .input-row--start {
      align-items: flex-start; }
    .input-row--margin-none {
      margin-bottom: 0; }
  .input-title {
    display: block;
    font-size: 16px;
    line-height: 19px; }
  .input__header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .input__switch {
    margin-bottom: 8px; }
  .input__name {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500; }
    .input__name--center {
      text-align: center; }
    .input__name--bold {
      font-weight: 700; }
  .input__notification {
    margin: 8px 0 0 0;
    font-size: 14px;
    color: var(--ui-error-color); }
    .input__notification--regular {
      color: #999999; }
  .input--error .input-item {
    border: 1px solid var(--ui-error-color); }
  .input--success .input-item {
    border: 1px solid var(--ui-success-color); }

.input-textarea {
  min-height: 100px; }

.input-notify {
  display: flex;
  align-items: flex-start;
  padding-top: 6px; }
  .input-notify--padding-none {
    padding-bottom: 0; }
  .input-notify__link {
    font-weight: 400;
    display: inline;
    font-size: 12px;
    line-height: 22px; }
  .input-notify__text {
    display: block;
    font-size: 12px;
    line-height: 22px;
    padding-left: 12px;
    color: #8f9bba; }
  .input-notify__char {
    display: block;
    font-size: 14px;
    line-height: 22px; }

.input-wrapper {
  position: relative; }
  .input-wrapper--flex {
    flex: 1;
    display: flex;
    align-items: center; }
  .input-wrapper--value::after {
    position: absolute;
    content: '%';
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    line-height: normal; }
  .input-wrapper--value .input-item {
    padding: 0 25px 0 10px; }

.input-item {
  width: 100%;
  height: 48px;
  font-size: 16px;
  padding: 0 15px;
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  line-height: normal;
  transition: all 0.15s ease; }
  .input-item.placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:-moz-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item::-moz-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:-ms-input-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item::-webkit-input-placeholder {
    color: #9f9f9f;
    transition: all 0.15s ease;
    font-size: 16px;
    line-height: 22px; }
  .input-item:focus {
    box-shadow: 0 0 4px #53c48a; }
    .input-item:focus::placeholder {
      opacity: 0; }
  .input-item--textarea {
    padding: 15px 12px;
    height: 108px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
    .input-item--textarea::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px; }
  .input-item--full-height {
    height: 100%; }
  .input-item--padding {
    padding: 8px 52px 8px 16px; }
  .input-item--transparent {
    border: 0;
    background-color: transparent;
    padding: 0;
    flex: 1;
    min-width: 5px; }
    .input-item--transparent:focus {
      box-shadow: none; }
  .input-item--green {
    font-size: 14px;
    color: #53C48A; }
  .input-item--left-icon {
    padding-left: 52px; }
  .input-item--right-icon {
    padding-right: 52px; }
  .input-item--bigger {
    height: 52px;
    font-size: 16px; }
  .input-item--small {
    width: 102px;
    height: 48px; }

.input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  left: 15px;
  width: 24px; }
  .input-icon--small {
    width: 19px; }
  .input-icon svg {
    width: 100%;
    height: auto; }
  .input-icon .stroke path {
    stroke: #b6b6b6; }
  .input-icon--right {
    left: unset;
    right: 15px; }

.password-type {
  display: none; }

input[type='password'] ~ .show-pass .password-type {
  display: block; }

input[type='password'] ~ .show-pass .text-type {
  display: none; }

.show-pass {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: flex;
  width: 16px; }
  .show-pass svg {
    width: 100%;
    height: auto; }
  .show-pass .fill path {
    fill: #b6b6b6; }
  .show-pass .stroke path {
    stroke: #b6b6b6; }

.select-block__name {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500; }

.select {
  position: relative;
  display: flex;
  align-content: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid var(--ui-input-border-color);
  background: #fff;
  z-index: 3; }
  .select__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }
  .select__current-arrow {
    display: flex;
    flex: 0 0 8px;
    max-width: 8px;
    margin-left: 10px; }
    .select__current-arrow .stroke path {
      stroke: #424242; }
  .select__current-text {
    margin-right: auto; }
  .select__img {
    display: flex;
    width: 16px;
    align-items: center;
    margin-right: 12px; }
  .select__drop {
    display: none;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    padding: 5px;
    background: #ffffff;
    border: 1px solid var(--ui-select-border);
    border-radius: 4px;
    z-index: 1; }
    .select__drop ul > li > a,
    .select__drop ul > li > button {
      display: block;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      color: #000;
      transition: all 0.15s ease;
      text-align: left; }
      .select__drop ul > li > a:hover,
      .select__drop ul > li > button:hover {
        background: #f4f4f4; }
  .select__drop-scroll {
    max-height: 180px;
    overflow: auto;
    scrollbar-color: #b6b6b6 transparent;
    scrollbar-width: thin; }
    .select__drop-scroll::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .select__drop-scroll::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: #b6b6b6; }
  .select.active {
    z-index: 5; }
    .select.active .select__drop {
      display: block; }
  .select--small {
    padding: 0;
    border: 0;
    width: 62px;
    z-index: auto; }
    .select--small .select__drop {
      padding: 0;
      min-width: 100px; }
  .select--grey {
    background: #dadada;
    padding: 0 15px;
    width: 74px; }
  .select--light .select__current {
    color: #b6b6b6;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px; }
  .select--big {
    height: 52px;
    font-size: 16px; }

/*--------------End_Inputs-----------*/
/*--------------Buttons-------------*/
button {
  background: none;
  border: none;
  outline: none;
  padding: 0; }

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 110px;
  height: 48px;
  padding: 0 15px;
  background: var(--ui-button-bg);
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  cursor: pointer;
  transition: all 0.15s ease; }
  .button:hover {
    background: #3eb678; }
  .button--type2 {
    background: transparent;
    border: 1px solid var(--ui-button-bg);
    color: #000; }
    .button--type2:hover {
      background: var(--ui-button-bg);
      color: #fff; }
  .button--red {
    background-color: #eb5757; }
  .button--second-type {
    background: transparent;
    border: 1px solid var(--ui-button-bg);
    color: var(--ui-button-bg);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px; }
    .button--second-type .stroke path {
      stroke: var(--ui-button-bg); }
    .button--second-type:hover {
      background: var(--ui-button-bg);
      color: #fff; }
      .button--second-type:hover .stroke path {
        stroke: #fff; }
  .button--green {
    background-color: #5bc044; }
    .button--green:hover {
      background: #27ae60; }
  .button--purple {
    background-color: #7840b0; }
    .button--purple:hover {
      background-color: #5e2a92; }
  .button--grey {
    background-color: #c4c4c4; }
    .button--grey:hover {
      background: #a6a6a6; }
  .button--rounded {
    border-radius: 22px; }
  .button--less-height {
    height: 36px; }
  .button--bigger-height {
    height: 60px;
    font-size: 18px; }
  .button--full-width {
    width: 100%; }
  .button--smallest {
    width: 83px;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    min-width: 83px;
    padding: 0;
    border-radius: 8px; }
  .button--small {
    width: 120px; }
  .button--regular {
    width: 160px; }
  .button--wide {
    width: 180px; }
  .button--wider {
    width: 200px; }
  .button--big {
    width: 240px; }
  .button:disabled {
    opacity: 0.5;
    background: #b6b6b6;
    cursor: default; }
  .button__text {
    display: block;
    padding-left: 16px; }
    .button__text--padding-none {
      padding-right: 0;
      padding-left: 0; }
  .button__arrow {
    display: flex;
    align-items: center;
    margin: 0 9px;
    width: 6px; }
  .button__icon {
    display: flex;
    width: 12px;
    margin-left: 10px; }
    .button__icon .fill path {
      fill: #fff; }

/*--------------End_Buttons---------*/
/*-----Checkbox and Radiobutton-----*/
.checkbox {
  margin: 0 0 10px 0; }
  .checkbox__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 16px;
    height: 16px;
    border: 1px solid var(--ui-checkbox-border);
    border-radius: 2px;
    cursor: pointer; }
  .checkbox__item-icon {
    display: flex;
    width: 10px;
    opacity: 0; }
    .checkbox__item-icon .fill path {
      fill: #fff; }
  .checkbox__label {
    display: flex;
    align-items: center; }
  .checkbox__text {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer; }
    .checkbox__text a {
      color: blue; }
  .checkbox input:checked ~ .checkbox__item {
    background: var(--ui-checkbox-bg);
    border-color: var(--ui-checkbox-bg); }
    .checkbox input:checked ~ .checkbox__item .checkbox__item-icon {
      opacity: 1; }
  .checkbox input:disabled ~ .checkbox__item {
    cursor: default;
    background: #b6b6b6; }
    .checkbox input:disabled ~ .checkbox__item + .checkbox__text {
      cursor: default; }

.radio {
  margin: 0 0 10px 0; }
  .radio__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--ui-checkbox-border);
    border-radius: 50%;
    cursor: pointer; }
    .radio__item:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      z-index: 2;
      opacity: 0; }
  .radio__label {
    display: flex;
    align-items: center; }
  .radio__text {
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer; }
    .radio__text a {
      color: blue; }
  .radio input:checked ~ .radio__item {
    background: var(--ui-checkbox-bg);
    border-color: var(--ui-checkbox-bg); }
    .radio input:checked ~ .radio__item:after {
      opacity: 1; }
  .radio input:disabled ~ .radio__item {
    cursor: default;
    background: #b6b6b6; }
    .radio input:disabled ~ .radio__item + .radio__text {
      cursor: default; }

.switch {
  display: flex;
  align-items: center; }
  .switch__toggler {
    display: block;
    position: relative;
    width: 36px;
    height: 20px;
    border: 1px solid #53C48A;
    border-radius: 13px;
    cursor: pointer;
    background-color: #53C48A; }
    .switch__toggler:before {
      content: '';
      position: absolute;
      left: 1px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 100%;
      transition: 0.3s; }
  .switch input:checked ~ .switch__toggler {
    background: #53C48A; }
    .switch input:checked ~ .switch__toggler:before {
      left: 17px;
      background: #fff; }
  .switch__label + .switch__text {
    margin-left: 10px; }
  .switch__text {
    font-size: 14px;
    font-weight: 500; }
    .switch__text + .switch__label {
      margin-left: 10px; }
  .switch--type2 .switch__toggler {
    background: var(--ui-swith-color); }
    .switch--type2 .switch__toggler:before {
      background: #fff; }
  .switch--type2 input:checked ~ .switch__toggler {
    background: var(--ui-main-color); }
    .switch--type2 input:checked ~ .switch__toggler:before {
      background: #fff; }

/*---End_Checkbox and Radiobutton---*/
/*-------------Popup----------------*/
.popup-open {
  position: relative;
  overflow: hidden; }

.popup-window {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  z-index: 201;
  background: rgba(154, 159, 167, 0.8);
  backdrop-filter: blur(7px); }
  .popup-window__inside {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 20px; }

.popup {
  position: relative;
  width: 400px;
  max-width: 100%;
  border-radius: 10px;
  background: var(--ui-popup-bg);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  padding: 30px; }
  .popup--big {
    width: 612px;
    padding: 40px; }
  .popup--second-type {
    width: 531px; }
    .popup--second-type .popup-header__title {
      font-size: 18px;
      line-height: 18px;
      font-weight: normal; }
    .popup--second-type .popup__close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #eeeeee;
      padding: 8px; }
      .popup--second-type .popup__close path {
        fill: #999999; }
  .popup__img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eff4fb;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 16px;
    width: 65px;
    height: 65px; }
  .popup__close {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 20px;
    height: 20px; }
    .popup__close--absolute {
      position: absolute;
      right: 40px;
      top: 40px; }
      @media screen and (max-width: 575px) {
        .popup__close--absolute {
          top: 20px;
          right: 20px; } }
    .popup__close svg {
      width: 100%;
      height: auto; }
    .popup__close .fill path {
      fill: #000; }
  .popup__content--center {
    text-align: center; }
  .popup__content--padding {
    padding-top: 24px; }
  .popup__btn {
    margin-top: 24px;
    width: 212px;
    height: 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px; }
    .popup__btn--second-type {
      width: 220px;
      background-color: #000000;
      margin-top: 14px; }
      .popup__btn--second-type:hover {
        background-color: #252525; }
  .popup__title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 10px; }
    @media screen and (max-width: 575px) {
      .popup__title {
        font-size: 14px;
        line-height: 24px; } }
  .popup__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 30px; }
    @media screen and (max-width: 575px) {
      .popup__text {
        font-size: 14px;
        line-height: 24px; } }
    .popup__text--second-type {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px; }
  .popup__footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px; }
    @media screen and (max-width: 575px) {
      .popup__footer-btn {
        width: 100%; } }

.popup-header {
  display: flex;
  align-items: center; }
  .popup-header--center {
    justify-content: center; }
  .popup-header__title {
    text-align: center;
    font-size: 20px;
    font-weight: 700; }
    .popup-header__title--center {
      display: block;
      width: 100%;
      margin-bottom: 15px; }

.popup-body {
  margin-top: 20px; }

.popup-text {
  margin: 0 0 15px 0;
  line-height: 2;
  font-size: 15px; }
  .popup-text--center {
    text-align: center; }

.popup-submit {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .popup-submit--less-mt {
    margin-top: 15px; }
  .popup-submit--more-mt {
    margin-top: 45px; }
  .popup-submit__col {
    width: calc(50% - 10px); }
  .popup-submit--sb {
    justify-content: space-between; }

@media screen and (max-width: 767px) {
  .popup {
    padding: 20px 15px 20px 15px; }
  .popup-header__title {
    font-size: 21px; } }

/*-------------End_Popup------------*/
.link--green {
  color: #53C48A;
  display: block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  margin-top: 10px; }

.link--margin {
  margin-top: 24px; }

.link--left {
  text-align: left;
  margin-top: 0; }

.captcha {
  margin-bottom: 16px; }

.logo {
  display: flex;
  align-items: center; }
  .logo__icon {
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 48px;
    height: 48px; }
  .logo__text {
    font-family: 'Poppins', sans-serif;
    display: block;
    padding-left: 8px;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.05em;
    font-size: 16px;
    line-height: 130%; }
    .logo__text--bold {
      padding-left: 0;
      display: inline;
      font-weight: 900;
      font-size: 17px; }
    .logo__text--green {
      color: #6cf0b7; }
    .logo__text--small {
      padding-left: 0;
      font-weight: 700;
      font-size: 11px;
      letter-spacing: 0.15em; }

.form__row--center {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center; }

.fee-block {
  flex: 1; }

.preloader {
  display: flex;
  flex-direction: column;
  align-items: center; }

.preloader-img {
  animation: Frame_36384__rotation__1 1s 0ms linear infinite;
  width: 80px;
  height: 80px; }

.preloader-progress {
  width: 90px;
  height: 12px;
  background-color: #fff;
  margin-top: 24px;
  border-radius: 10px;
  padding: 3px; }
  .preloader-progress__success {
    display: block;
    width: 30%;
    height: 100%;
    border-radius: 20px;
    background-color: #000000; }

@keyframes Frame_36384__rotation__1 {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes Frame_36384__rotation__2 {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg); }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg); } }

@keyframes Frame_36384__rotation__3 {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(-180deg); }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(-360deg); } }

.tabs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 24px; }
  .tabs__content {
    width: 100%;
    padding-top: 30px; }
  .tabs-nav {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c9d4ea; }
    .tabs-nav__btn {
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
      border-bottom: 2px solid transparent;
      color: #8f9bba; }
      .tabs-nav__btn:hover {
        color: #53C48A; }
    .tabs-nav__item:not(:last-child) {
      margin-right: 55px; }
    .tabs-nav__item.active .tabs-nav__btn {
      color: #53C48A;
      font-weight: 500;
      border-bottom: 2px solid #53C48A; }

.steps-item:not(:last-child) {
  margin-bottom: 24px; }

.steps-item__header {
  display: flex;
  align-items: center; }

.steps-item__num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #53C48A;
  color: #fff;
  margin-right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px; }

.steps-item__title {
  display: block;
  flex: 1;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px; }

.steps-item__main {
  padding-top: 24px; }

.steps-item__desc {
  font-size: 14px;
  line-height: 22px;
  color: #68769f; }

.steps-item__footer {
  padding-top: 16px; }

.steps__code {
  max-width: 320px; }

.download-links {
  display: flex;
  align-items: center;
  gap: 24px; }

.qr-code {
  display: flex;
  align-items: center; }
  .qr-code__main {
    flex: 1;
    padding-left: 24px; }

.content-block--small {
  max-width: 615px; }

.content-block__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #c9d4ea; }

.content-block__title {
  display: block;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px; }

.content-block__main {
  padding-top: 15px; }

.content-block__desc {
  font-size: 14px;
  line-height: 22px;
  color: #68769f; }

.content-block__row {
  display: flex;
  align-items: center; }

.table--loans_list .tr {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; }

.edit-btn {
  width: 15px;
  height: 16px;
  display: flex;
  align-items: center;
  margin-left: auto; }
  .edit-btn:hover path {
    stroke: #53C48A; }
  .edit-btn path {
    transition: 0.2s; }

.check-btn {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px; }
  .check-btn--hide {
    display: none; }

.hot-wallets--active .table-body .tr {
  background-color: #e9edf7; }

.hot-wallets--active .edit-btn {
  display: none; }

.hot-wallets--active .check-btn--hide {
  display: flex; }

.hot-wallets--active .hot-wallets__input {
  border: 1px solid #dfdfdf;
  padding: 0 10px; }
  .hot-wallets--active .hot-wallets__input:focus {
    box-shadow: 0 0 4px #53c48a; }
    .hot-wallets--active .hot-wallets__input:focus::placeholder {
      opacity: 0; }

.hot-wallets__input {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  border: 0;
  padding: 0; }
  .hot-wallets__input:focus {
    box-shadow: none; }

.param-list {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  max-width: 300px;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 24px; }

.param-item__title {
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #a3aed0;
  padding-bottom: 11px; }

.param-item__desc {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  word-break: break-all; }

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px; }

.input-data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
  padding-top: 10px; }
  .input-data__num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    max-width: 22px;
    min-width: 22px;
    height: 22px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background-color: #ef4444;
    border-radius: 50%;
    margin-right: 5px; }

.input-right {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 12px; }

.data-user {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: #eff4fb;
  border-radius: 50px;
  white-space: nowrap; }
  .data-user__img {
    min-width: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px; }
  .data-user__name {
    display: block;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px; }
  .data-user__close {
    display: flex;
    align-items: center;
    width: 12px;
    height: 12px;
    margin-left: 8px; }

.data-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 3px;
  margin-top: 16px;
  padding: 8px 16px;
  z-index: 1; }
  .data-dropdown__item {
    padding: 8px 0;
    width: 100%;
    background-color: transparent;
    border-radius: 4px;
    transition: 0.2s; }
    .data-dropdown__item:not(:last-child) {
      margin-bottom: 8px; }
    .data-dropdown__item:hover {
      background-color: #eff4fb;
      padding: 8px; }

.table-adress__row {
  display: flex;
  align-items: center;
  gap: 14px; }

.table-adress__btn {
  display: flex;
  align-items: center; }

.table-adress__text {
  flex: 1; }

.buttons-row {
  display: flex;
  gap: 8px;
  flex-wrap: wrap; }

@media screen and (max-width: 1200px) {
  .td--flex {
    flex-direction: column; } }

@media screen and (max-width: 991px) {
  .content-block--margin {
    margin-left: 0;
    margin-right: 0;
    width: 100%; } }

@media screen and (max-width: 768px) {
  .content-block__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px; } }

@media screen and (max-width: 575px) {
  .fee-block {
    width: 100%;
    flex: 0 1 auto; }
  .input-row--column {
    flex-direction: column;
    align-items: flex-start; }
  .input-wrapper--flex {
    flex: 0 1 auto;
    width: 100%; }
  .title {
    font-size: 24px; }
  .logo__icon {
    width: 24px; }
  .logo__text {
    font-size: 12px; }
    .logo__text--bold {
      font-size: 14px; }
  .tabs-nav {
    flex-direction: column;
    width: 100%; }
    .tabs-nav__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px; }
  .qr-code {
    flex-direction: column; }
    .qr-code__main {
      padding-left: 0;
      padding-top: 15px;
      width: 100%; } }
