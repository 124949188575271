.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.notification-enter.notification-enter-active {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.notification-enter.notification-enter-active,
.notification-leave {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}
.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

/* //------------------- */
.notification-container {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 999999;
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  min-height: max-content;
  right: 10px;
  bottom: 10px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.notification {
  box-sizing: border-box;
  padding: 8px 10px 8px 10px;
  border-radius: 3px;
  background-color: #2b2f36;
  box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.11);
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  /* position: relative; */
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  opacity: 0.9;
}

.notification .title {
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  width: 28px;
  height: 100%;
  display: flex;
  align-items: flex-start;
}

.notification-info {
  /* background-color: #fff; */
  /* border: 1px dashed #777; */

  color: #fff !important;
}
.notification-info .message {
  color: #fff;
}

.notification-info:before {
  content: url('./styles/icon/info.svg');
}

.notification-success {
  /* background-color: #000; */
  /*border: 1px dashed #000; */
}

.notification-success .message {
  color: #fff;
}

.notification-success:before {
  content: url('./styles/icon/success.svg');
}

.notification-warning {
  /* background-color: #e6e6e6;
  border: 1px solid #777; */
  color: #fff;
}

.notification-warning .message {
  color: #fff;
}

.notification-warning:before {
  content: url('./styles/icon/warning.svg');
}

.notification-error {
  /* border: 1px solid #777;
  background-color: #777; */
  color: #fff;
}

.notification-error .message {
  color: #fff;
}

.notification-error:before {
  content: url('./styles/icon/error.svg');
}

.notification-message {
  padding: 0;
}

.notification-message .message {
  background: transparent;
  background-color: transparent;
  /* padding: 4px; */
}
.grecaptcha-badge {
  display: none;
}
.pagination li.active a {
  border-color: #5bc044;
  color: #5bc044;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}
/*style for transactionsManagement*/
.table.table--transactions .tr {
  grid-template-columns: 1fr 1fr 1fr 0.8fr 2.5fr 1fr 1fr 1fr 1fr;
}
.table-wrapper tr {
  border-bottom: 1px solid #ccc;
}
.table-wrapper tr th {
  font-weight: bold;
}
.table-wrapper tr th,
.table-wrapper tr td {
  text-align: left;
  padding: 13px 25px;
  font-size: 12px;
}
.actions-buttons {
  display: flex;
  gap: 10px;
}
.popup--second-type .popup__close:hover {
  background: #53c48a;
  color: #fff;
}
.popup--second-type .popup__close:hover svg path {
  stroke: #fff;
}
.actions-buttons .button--red {
  color: #fff;
}
.actions-buttons .button--red:hover {
  background-color: #cb4545;
}
.not-found td {
  text-align: center !important;
}
.delete svg path {
  stroke: #eb5757;
}
.table-top-bar {
  display: flex;
  justify-content: space-between;
}
.tabs-nav {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff;
}
.tabs-nav__item:not(:last-child) {
  margin-right: 10px;
}
.tabs-nav__btn {
  padding: 10px 20px;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 2px solid transparent;
  color: #808080;
  background: #fff;
}
.tabs-nav__item.active .tabs-nav__btn {
  color: #fff;
  font-weight: 500;
  border-bottom: 2px solid #53c48a;
  background: #53c48a;
  border-radius: 4px 4px 0 0;
}
.address .table-adress__text {
  flex: unset;
}
.full-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background: #ffffffba;
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #53c48a;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.custom-view .wallet-item {
  display: flex;
  align-items: center;
  width: 31.5%;
}
.custom-view .wallet-item__withdrawal {
  margin-left: 37px;
  width: 140px;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}
.custom-view .wallet-item__img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eaf8f0;
  border-radius: 13px;
  width: 52px;
  height: 52px;
}
.custom-view .wallet-item__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-view .wallet-item__info {
  padding-left: 13px;
}
.custom-view .wallet-item__title {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #a3aed0;
}
.custom-view .wallet-item__desc {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.custom-view .wallet-item__desc--green {
  display: inline;
  color: #53c48a;
  padding-left: 10px;
}
.custom-view .wallet-item__desc--big {
  font-size: 20px;
  line-height: 28px;
}
.custom-view .wallet-item__desc--right {
  display: block;
  text-align: right;
}

.custom-view .wallet-detail {
  display: flex;
  align-items: center;
}
.custom-view .wallet-detail__content {
  padding-left: 24px;
  flex: 1;
}
.custom-view .wallet-detail .wallet-detail__list {
  display: flex;
  align-items: center;
  gap: 45px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 24px;
}
.custom-view .wallet-detail__item {
  width: auto;
}
.custom-view .wallet-detail__footer {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  padding-top: 24px;
  border-top: 1px solid #c9d4ea;
  gap: 16px;
}
.custom-view .doughnut-wrapper div.progressbar__text {
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 22px;
  color: #000;
  font-weight: 500;
}

.custom-view .doughnut-wrapper div.progressbar__text .progressbar__text--small {
  font-size: 14px;
  color: #b5b5b5;
}
.custom-view .content-block__main--padding{
  padding: 0;
}
.custom-view .wallet-detail__footer .table-block {
  width: 100%;
}
@media screen and (max-width: 768px) {

 .custom-view  .wallet-detail {
    flex-direction: column;
    align-items: flex-start;
}
.custom-view .wallet-detail__content {
  padding-left: 0;
  padding-top: 20px;
}
.custom-view .wallet-item__icon {
  width: 30px;
  height: 30px;
}
}
.table-wrapper {
  overflow-x: auto;
}
.table-adress__btn svg {
  width: 16px;
}

.complete_profile_tbl tr th, .complete_profile_tbl tr td{
  white-space: nowrap;
}